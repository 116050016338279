// extracted by mini-css-extract-plugin
export const container = "styles-module--container--2Lsql";
export const show = "styles-module--show--31xKV";
export const front = "styles-module--front--UlSgg";
export const animate = "styles-module--animate--1QOhD";
export const easeinleft = "styles-module--easeinleft--nmpGC";
export const fixed = "styles-module--fixed--1cN79";
export const fadeout = "styles-module--fadeout--3nc7Z";
export const hide = "styles-module--hide--1sEni";
export const content = "styles-module--content--1RNmP";
export const info = "styles-module--info--3jos-";
export const contact = "styles-module--contact--25fCI";
export const title = "styles-module--title--1A8tj";
export const showlogos = "styles-module--showlogos--2sxZ3";
export const logos = "styles-module--logos--15vgr";
export const email = "styles-module--email--1U0xC";
export const icon = "styles-module--icon--1BHTN";
export const bounce = "styles-module--bounce--HJBt7";