import * as React from 'react';
import classnames from 'classnames';
import Player from '@vimeo/player';
import * as styles from './styles.module.scss';

interface Props {
    iframeOpacity: number
    onReady: () => void
    children?: JSX.Element | JSX.Element[]
}

interface ContainerState {
    height: number
    width: number
    top: number
    left: number
}

const Backgroundvideo = (props: Props) => {
    const aspectRatio = 16 / 9;
    const [containerAttributes, setContainerAttributes] = React.useState<ContainerState>({
        height: 0,
        width: 0,
        top: 0,
        left: 0
    });

    function updateContainerAttributes() {
        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        const containerAspectRatio = containerWidth / containerHeight

        let height = containerHeight
        let width = containerWidth
        let top = 0
        let left = 0

        if (containerAspectRatio > aspectRatio) {
            height = containerWidth / aspectRatio
            top = (height - containerHeight) / -2
        } else {
            width = containerHeight * aspectRatio
            left = (width - containerWidth) / -2
        }

        setContainerAttributes({
            height,
            width,
            top,
            left
        });
    }

    function getStyle(): React.CSSProperties {
        return {
            height: `${containerAttributes.height}px`,
            width: `${containerAttributes.width}px`,
            top: `${containerAttributes.top}px`,
            left: `${containerAttributes.left}px`,
        }
    }

    React.useEffect(() => {
        updateContainerAttributes();
        window.addEventListener("resize", updateContainerAttributes);

        return () => window.removeEventListener("resize", updateContainerAttributes);
    }, []);

    function checkVideoProgress(val: {seconds: number, percent: number, duration: number}) {
        if (val.percent > 0.19) props.onReady();
    }

    React.useEffect(() => {
        const player = new Player(iframeRef.current);

        player.on('progress', checkVideoProgress);

        return () => {
            player.off('progress', checkVideoProgress);
        }
    }, []);

    const containerRef = React.useRef(null);
    const iframeRef = React.useRef(null);

    return (
        <div
            ref={containerRef}
            style={getStyle()}
            className={styles.container}
        >
            <iframe
                className={classnames(styles.iframe)}
                style={{opacity: props.iframeOpacity}}
                ref={iframeRef}
                src="https://player.vimeo.com/video/539310932?loop=1&amp;background=1&amp;app_id=122963"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            ></iframe>
            {props.children}
        </div>
    );
}

export const Component = Backgroundvideo;
