import React from 'react';
import classnames from 'classnames';

import * as Components from '../components/';

import * as styles from './styles.module.scss';
import ChevronDown from '../assets/icons/chevrondown.svg';

enum Screen {
    Front,
    Info,
    Contact
}

const DEBUG = Screen.Front;

export default function Home({ data }) {
    const letters = 9;
    const delay = 200;
    const totalDelay = (letters - 1) * delay;

    const [loadingProgress, setLoadingProgress] = React.useState(DEBUG === Screen.Front ? 0 : 100);
    const [videoReady, setVideoReady] = React.useState(false);
    const [logoRendered, setLogoRendered] = React.useState(false);
    const [bounce, setBounce] = React.useState(false);

    const [hasReachedPastSecondScreen, setHasReachedPastSecondScreen] =
        React.useState(false);

    const [currentScreen, setCurrentScreen] = React.useState(DEBUG === null ? Screen.Front : DEBUG);
    const [showSkills, setShowskills] = React.useState(false);
    const [visibleSkills, setVisibleSkills] = React.useState(false);

    const [hideIcons, setHideIcons] = React.useState(true);
    const [invisIcons, setInvisIcons] = React.useState(true);

    const videoStatus = React.useRef(null);
    const frontAnimationRef = React.useRef({ triggered: false });
    const timeOuts = React.useRef({ refs: [] });

    React.useEffect(() => {
        if (videoReady && !frontAnimationRef.current.triggered) {
            frontAnimationRef.current.triggered = true;
            pushTimeout(setTimeout(() => {
                setLogoRendered(true);
                setIcons(true);
                pushTimeout(setTimeout(() => setBounce(true), 3000));
            }, totalDelay + 500));
        }
    });

    React.useEffect(() => {
        increamentLoading(0, loadingProgress);
    }, []);

    function pushTimeout(timeoutId: number) {
        timeOuts.current.refs.push(timeoutId);
    }

    function flushTimeouts() {
        timeOuts.current.refs.forEach((timeoutId) => clearTimeout(timeoutId));
        timeOuts.current.refs = [];
    }

    function setIcons(show: boolean) {
        setHideIcons(!show);
        setTimeout(() => setInvisIcons(!show), 200);
    }

    function randomNumber(from: number, to: number) {
        const interval = to - from;

        const generated = from + Math.floor(Math.random() * interval);
        const toReturn = Math.max(from, generated);

        return toReturn;
    }

    function increamentLoading(timePassed: number, currentProgress: number) {
        let nextProgress = currentProgress;
        let nextDelay = 0;

        const exitFunction = (exitDelay: number) => {
            setTimeout(() => {
                setLoadingProgress(100);
                setTimeout(() => setVideoReady(true), 1200);
            }, exitDelay);
        }

        if (DEBUG !== Screen.Front || videoStatus.current) {
            return exitFunction(1);
        }

        if (timePassed < 1500) {
            nextProgress = randomNumber(currentProgress + 1, currentProgress + 10);
            nextDelay = randomNumber(40, 200);
        }

        else if (timePassed < 2500) {
            nextProgress = randomNumber(currentProgress + 1, currentProgress + 5);
            nextDelay = randomNumber(100, 300);
        }

        else {
            if (timePassed > 10000) {
                return exitFunction(1);
            }
            nextProgress = randomNumber(currentProgress + 1, currentProgress + 2);
            nextDelay = randomNumber(750, 1500);
        }

        if (nextProgress > 99) {
            nextProgress = 99;
        }

        setLoadingProgress(nextProgress);
        setTimeout(() => {
            increamentLoading(timePassed + nextDelay, nextProgress)
        }, nextDelay);
    }

    React.useEffect(() => {
        if (DEBUG === Screen.Info) {
            setTimeout(() => setShowskills(true), 3000);
        }
    }, []);

    function contentClass() {
        switch (currentScreen) {
            case Screen.Info:
                return styles.info;
            case Screen.Contact:
                return styles.contact;
            default:
                return null;
        }
    }

    function getVideoOpacity(): number {
        if (videoStatus.current == null) return 0;
        if (currentScreen !== Screen.Front) return 0.1;
        if (logoRendered && videoStatus.current) return 0.2;

        return 0;
    }

    return (
        <>
            <Components.Progressbar.Component progress={loadingProgress} />
            <div
                className={classnames(
                    styles.container,
                    videoReady && styles.show
                )}
            >
                <h1>Cloudtide</h1>
                <Components.BackgroundVideo.Component
                    iframeOpacity={getVideoOpacity()}
                    onReady={() => {
                        if (videoStatus.current == null) {
                            videoStatus.current = new Date();
                        }
                    }}
                />

                <div className={classnames(
                    styles.front,
                    currentScreen !== Screen.Front && styles.fixed
                )}>
                    <div
                        className={classnames(
                            hasReachedPastSecondScreen && invisIcons && styles.fadeout,
                            hasReachedPastSecondScreen && hideIcons && styles.hide
                        )}
                    >
                        {'Cloudtide'.split('').map((letter, index) => {
                            if (!videoReady) return null;
                            return <HeaderLetter letter={letter} delay={index * delay} key={index} />
                        })}
                    </div>
                </div>
                <div
                    className={classnames(
                        styles.content,
                        contentClass(),
                    )}>
                    <div></div>
                    <div className={styles.info}>
                        <div
                            className={classnames(
                                styles.title,
                                showSkills && styles.showlogos
                            )}
                        >
                            <span>
                                Web engineer consultant
                            </span>
                                <div
                                className={classnames(
                                    styles.logos,
                                    showSkills && styles.showlogos
                                )}
                            >
                                <Components.Skillset data={data} show={visibleSkills} />
                            </div>
                        </div>
                        <div
                            className={classnames(
                                styles.logos,
                                showSkills && styles.showlogos
                            )}
                        >
                            <Components.Skillset data={data} show={visibleSkills} />
                        </div>
                    </div>

                    <div className={styles.contact}>
                        <Email />
                    </div>
                </div>

                <div className={classnames(
                    styles.icon,
                    invisIcons && styles.fadeout,
                    hideIcons && styles.hide
                )}>
                    <div className={classnames(
                        logoRendered && styles.show,
                        bounce && styles.bounce,
                        currentScreen === Screen.Contact && styles.contact
                    )}
                        onClick={() => {
                            flushTimeouts();
                            if (currentScreen === Screen.Front) {
                                setCurrentScreen(Screen.Info);
                                setIcons(false);
                                setBounce(false);

                                pushTimeout(setTimeout(() => {
                                    setShowskills(true);
                                    pushTimeout(setTimeout(() => {
                                        setVisibleSkills(true)
                                        pushTimeout(setTimeout(() => setIcons(true), 500));
                                    }, 500));
                                }, 1200));
                            }
                            else if (currentScreen === Screen.Info) {
                                setCurrentScreen(Screen.Contact);
                                setHasReachedPastSecondScreen(true);
                                setIcons(false);
                                pushTimeout(setTimeout(() => {
                                    setIcons(true);
                                }, 500));
                            }
                            else if (currentScreen === Screen.Contact) {
                                setCurrentScreen(Screen.Info);
                                setIcons(false);
                                pushTimeout(setTimeout(() => {
                                    setIcons(true);
                                }, 500));
                            }
                        }}
                    >
                        <ChevronDown />
                    </div>
                </div>
            </div>
        </>
    )
}

const HeaderLetter = ({ letter, delay }) => {
    const [className, setClassname] = React.useState(null);

    React.useEffect(() => {
        setTimeout(() => setClassname(styles.animate), delay)
    }, []);

    return <h2 className={className}>{letter}</h2>;
}

const Email = () => {
    return (
        <div className={styles.contact}>
            <span className={styles.email} data-user="tcatnoc" data-website="oi.editduolc"></span>
            <div>
                <div>© {new Date().getFullYear()} Cloudtide AB</div>
            </div>
        </div>
    )
}
