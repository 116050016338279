import * as React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import RelaySVG from '../assets/img/logos/relay.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
    data: any
    show: boolean
}

const Skillset = (props: Props) => {
    const { data } = props;
    return (
        <div className={styles.container}>
            <div
                className={classnames(
                    styles.skills,
                    props.show && styles.show
                )
            }>
                <span>TypeScript</span>
                <span>React</span>
                <span>Apollo</span>
                <span>Relay</span> {/* 4 */}
                <span>GraphQL</span>
                <span>REST</span> {/* 6 */}
                <span>Next.js</span>
                <span>Gatsby</span> {/* 8 */}
                <span>Node.js</span>
                <span>django</span>{/* 10 */}
                <span>python</span>
                <span>ASP MVC</span> {/* 12 */}
                <span>.Net</span>
                <span>Elasticsearch</span> {/* 14 */}
                <span>SQL</span>
                <span>redis</span> {/* 16 */}
                <span>webpack</span>
                <span>Docker</span> {/* 18 */}
            </div>
        </div>
    )
}

// interface ImageProps {
//     data?: any
//     svg?: any
//     id: string
//     label: string
// }

// const Image = (props: ImageProps) => {
//     return (
//         <div className={styles.image}>
//             {props.svg == null &&
//                 <GatsbyImage
//                     image={props.data[props.id].childImageSharp.gatsbyImageData}
//                     alt={props.label}
//                 />
//             }
//             {props.svg != null && props.svg}
//             <span>{props.label}</span>
//         </div>
//     )
// }

export default Skillset;
