import * as React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

interface Props {
    progress: number
}


const Progressbar = (props: Props) => {
    return (
        <div
            className={classnames(
                styles.progressbar,
                props.progress === 100 && styles.loaded
            )}
        >
            <div>
                <div style={{ width: `${props.progress}%` }}></div>
            </div>
        </div>
    )
}

export const Component = Progressbar;
